import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "list-container" }
const _hoisted_2 = ["href"]
const _hoisted_3 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Icon = _resolveComponent("Icon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("ul", null, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.list, (item) => {
        return (_openBlock(), _createElementBlock("li", {
          key: item.id
        }, [
          _createElementVNode("a", {
            href: item.path,
            target: "_blank"
          }, _toDisplayString(item.name), 9, _hoisted_2),
          (_ctx.edit)
            ? (_openBlock(), _createElementBlock("span", {
                key: 0,
                class: "delete",
                onClick: ($event: any) => (_ctx.onDel(item.deletekey))
              }, [
                _createVNode(_component_Icon, {
                  width: 30,
                  height: 30,
                  path: _ctx.mdiDeleteForever
                }, null, 8, ["path"])
              ], 8, _hoisted_3))
            : _createCommentVNode("", true)
        ]))
      }), 128))
    ])
  ]))
}