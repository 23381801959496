
import { computed, defineComponent, ref } from 'vue';
import { mdiCheck } from '@mdi/js';

export default defineComponent({
  props: {
    label: { type: String },
    modelValue: Boolean,
  },
  setup(props, { emit }) {
    const model = computed({
      get: () => props.modelValue,
      set: (check: boolean) => emit('update:modelValue', check),
    });

    const toggle = () => {
      model.value = !model.value;
    };

    return {
      model,
      toggle,
      mdiCheck,
    };
  },
});
