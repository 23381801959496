import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-af4e9bc2"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "box" }
const _hoisted_2 = {
  key: 0,
  class: "label"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Icon = _resolveComponent("Icon")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["checkbox", { checked: _ctx.model }]),
    onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.toggle && _ctx.toggle(...args)))
  }, [
    _createElementVNode("div", _hoisted_1, [
      (_ctx.model)
        ? (_openBlock(), _createBlock(_component_Icon, {
            key: 0,
            width: 25,
            height: 25,
            path: _ctx.mdiCheck
          }, null, 8, ["path"]))
        : _createCommentVNode("", true)
    ]),
    (_ctx.label)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, _toDisplayString(_ctx.label), 1))
      : _createCommentVNode("", true)
  ], 2))
}