
import { defineComponent, onMounted, ref } from 'vue';
import { useStore } from 'vuex';

import MultiUpload from '../../../components/util/MultiUpload.vue';
import FileList from '../../../components/FileList.vue';
import UserDropdown from '../../../components/custom/UserDropdown.vue';
import { mdiMenuDown, mdiCancel } from '@mdi/js';

import { SkillRecord } from '../skills_new/Personal.vue';
import router from '@/router';

export default defineComponent({
  components: {
    MultiUpload,
    FileList,
    UserDropdown,
    // Zusammenarbeit,
  },
  props: {
    userid: { type: Number },
    onSave: { type: Function, required: true },
  },
  setup(props) {
    const skills = ref<Array<any>>([]);
    const store = useStore();

    const gradingusers = ref([]);
    const user = ref();

    const onAdded = (id: number) => {
      store.dispatch('saveFiles', { userid: props.userid, file: id }).then(() => {
        store.dispatch('fetchUserData', props.userid).then((res: any) => {
          user.value.files = JSON.parse(res.files);
        });
      });
    };

    const onDelete = () => {
      store.dispatch('fetchUserData', props.userid).then((res: any) => {
        user.value.files = JSON.parse(res.files);
      });
    };

    const fixed = ref(false);
    const container = ref();

    const loading = ref(true);

    // SKILLMATRIX
    const skillmatrix = ref<{ [propType: number]: { self: number; value: number; manager: number; set: number; target: number; detailed: any } }>({});

    const saveNotiz = () => {
      store.dispatch('saveNotiz', { id: props.userid, notiz: user.value.notiz }).then(() => {
        store.dispatch('addNotification', { message: 'Gespeichert' });
      });
    };

    const saveSet = (skillid: number) => {
      const value = skillmatrix.value[skillid].set;

      store.dispatch('saveSet', { id: props.userid, skillid, value }).then(() => {
        store.dispatch('addNotification', { message: 'Gespeichert' });
      });
    };

    const saveTarget = (skillid: number) => {
      const value = skillmatrix.value[skillid].target;
      store.dispatch('saveTarget', { id: props.userid, skillid, value }).then(() => {
        store.dispatch('addNotification', { message: 'Gespeichert' });
      });
    };

    onMounted(() => {
      if (props.userid) {
        store.dispatch('fetchWorkers');

        store.dispatch('fetchUserData', props.userid).then((res: any) => {
          user.value = res;
          user.value.files = JSON.parse(res.files);
          user.value.unit_id = parseInt(user.value.unit_id);

          store.dispatch('fetchPersonalGrades', props.userid).then((res2: any) => {
            if (!res2.meta.isManager) router.replace({ name: 'Index' });
            skills.value = res2.meta.skills
              .sort(store.getters.getSort())
              .filter((item: SkillRecord) => !item.leadership || (item.leadership && user.value.data['leadershipskills']));
            gradingusers.value = res2.meta.gradingusers;

            const grades = res2.result;
            for (const skill of skills.value) {
              skillmatrix.value[skill.id] = {
                self: grades[skill.id]?.self ? parseFloat(grades[skill.id].self.toFixed(2)) : -1,
                value: grades[skill.id]?.value ? parseFloat(grades[skill.id].value.toFixed(2)) : -1,
                manager: grades[skill.id]?.manager ? parseFloat(grades[skill.id].manager.toFixed(2)) : -1,
                set: grades[skill.id]?.set ? parseFloat(grades[skill.id].set.toFixed(2)) : -1,
                target: grades[skill.id]?.target ? parseFloat(grades[skill.id].target.toFixed(2)) : -1,
                detailed: grades[skill.id]?.detailed,
              };
            }

            loading.value = false;
          });
        });
      }
    });

    const filter = ref<number>(-1);

    const filterDetails = (id: number) => {
      filter.value = id;
    };

    const filteredskills = (filter: string) => skills.value.filter((item) => item.group == filter);

    const isFuture = (skill: SkillRecord) => {
      if (user.value.unit_id) return skill.future.includes(user.value.unit_id);
      return false;
    };

    const isStrategic = (skill: SkillRecord) => {
      if (user.value.unit_id) return skill.strategic.includes(user.value.unit_id);
      return false;
    };

    return {
      types: store.getters.getTypes,
      isFuture,
      isStrategic,
      skills,
      filteredskills,
      skillmatrix,
      name,
      loading,
      saveNotiz,
      saveSet,
      saveTarget,
      container,
      fixed,
      onDelete,
      onAdded,
      gradingusers,
      filter,
      filterDetails,
      mdiMenuDown,
      mdiCancel,
      user,
    };
  },
});
