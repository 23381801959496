
import { computed, defineComponent, onMounted, ref, toRef, watch } from 'vue';
import { useStore } from 'vuex';
import { mdiPlus, mdiMinus } from '@mdi/js';
import Checkbox from '@/components/util/Checkbox.vue';

export default defineComponent({
  props: {
    open: { type: Boolean, default: false },
    type: { type: String, default: 'personal' },
    unit: { type: Number },
  },
  components: {
    Checkbox,
  },
  emits: ['close'],
  setup(props, { emit }) {
    const store = useStore();
    const close = () => {
      emit('close');
    };
    // const selected = ref<Array<number>>([]);
    const open = toRef(props, 'open');

    const searching = ref('');

    const toggle = (id: number) => {
      items.value = items.value.map((item: any) => {
        if (item.id == id) return { ...item, selected: !item.selected };
        return item;
      });
    };

    const start = () => {
      close();
    };

    const items = ref<Array<{ id: number; name: string; selected: boolean }>>([]);

    onMounted(() => {
      if (props.type != 'unit') {
        store.dispatch('fetchUnits').then((res: any) => {
          items.value = res.map((item: any) => {
            return { id: item.id, name: item.name, selected: true };
          });
        });
      } else {
        store.dispatch('fetchWorkers').then((res: any) => {
          items.value = res
            .filter((item: any) => item.unit_id == props.unit)
            .map((item: any) => {
              return { id: item.id, name: `${item.firstname} ${item.lastname}`, selected: true };
            });
        });
      }
    });

    const selected = computed(() => {
      return items.value
        .filter((item: any) => item.selected)
        .map((item: any) => {
          return item.id;
        });
    });
    watch([open], () => {
      store.dispatch('setCompareFilter', selected.value);
    });

    const getName = (id: number) => {
      return items.value.find((item: any) => item.id == id)?.name;
    };

    const filtered = computed(() => {
      return items.value.filter((item) => item.name.toLowerCase().includes(searching.value.toLowerCase()));
    });

    const reset = () => {
      items.value = items.value.map((item: any) => {
        return { ...item, selected: true };
      });
      close();
    };

    return {
      close,
      toggle,
      filtered,
      // selected,
      start,
      searching,
      mdiPlus,
      mdiMinus,
      getName,
      items,
      reset,
    };
  },
});
