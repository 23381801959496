import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "multiupload" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Upload = _resolveComponent("Upload")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.active, (i) => {
      return (_openBlock(), _createBlock(_component_Upload, {
        key: i,
        onFinished: _ctx.onFinished,
        onDeleted: _ctx.onDeleted,
        onSelected: _ctx.onChanged,
        onUploading: _ctx.onChanged,
        onError: _ctx.onChanged,
        media: _ctx.media
      }, null, 8, ["onFinished", "onDeleted", "onSelected", "onUploading", "onError", "media"]))
    }), 128))
  ]))
}