
import { defineComponent, onMounted, PropType, ref, watch } from 'vue';
import { useStore } from 'vuex';
import { mdiDeleteForever } from '@mdi/js';

const APIURL = process.env.VUE_APP_API;

type FileListItem = {
  name: string;
  path: string;
  id: number;
  deletekey: string;
};

export default defineComponent({
  props: { files: { type: Array as PropType<Array<number>>, required: true }, edit: { type: Boolean, default: false } },
  emits: ['del'],
  setup(props, { emit }) {
    const list = ref<Array<FileListItem>>([]);
    const store = useStore();

    const onDel = (deletekey: string) => {
      store.dispatch('deleteFile', deletekey).then(() => {
        emit('del');
      });
    };

    const init = () => {
      list.value = [];
      for (const file of props.files) {
        store.dispatch('getFileInfo', file).then((res: any) => {
          list.value.push({
            name: res.name,
            path: `${APIURL}${res.path}`,
            id: res.id,
            deletekey: res.deletekey,
          });
        });
      }
    };

    watch(
      () => props.files,
      () => {
        init();
      }
    );
    onMounted(() => {
      init();
    });
    return { list, onDel, mdiDeleteForever };
  },
});
